<template>
    <div class="iqiyi">
        <div class="header">
            <img
                src="@/assets/images/icons/iqiyi-logo.png"
                class="logo">
            <div class="title">爱奇艺黄金VIP</div>
        </div>
        <div class="body">
            <div class="slogan">友拾惠联合爱奇艺送福利啦！<br>直充低至<span class="discount">8</span>折，赶紧来围观吧～</div>
            <div class="title">会员卡类</div>
            <ul class="list">
              <li
                class="item"
                @click="onRecharge('https://jz.bm724.com/juzi_zjj/productcharge.html?sn=YHD0001639')">
                <div class="tag">8折</div>
                <div class="time">7天</div>
                <div class="special-price"><span class="unit">¥</span>8</div>
                <div class="origin-price">¥10.00</div>
                <div class="recharge">立即充值<i class="arrow"/></div>
              </li>
              <li
                class="item"
                @click="onRecharge('https://jz.bm724.com/juzi_zjj/productcharge.html?sn=YHD0001637')">
                <div class="tag">8折</div>
                <div class="time">1个月</div>
                <div class="special-price"><span class="unit">¥</span>16</div>
                <div class="origin-price">¥20.00</div>
                <div class="recharge">立即充值<i class="arrow"/></div>
              </li>
              <li
                class="item"
                @click="onRecharge('https://jz.bm724.com/juzi_zjj/productcharge.html?sn=YHD0001638')">
                <div class="tag">8折</div>
                <div class="time">3个月</div>
                <div class="special-price"><span class="unit">¥</span>46.4</div>
                <div class="origin-price">¥58.00</div>
                <div class="recharge">立即充值<i class="arrow"/></div>
              </li>
              <li
                class="item"
                @click="onRecharge('https://jz.bm724.com/juzi_zjj/productcharge.html?sn=YHD0001640')">
                <div class="tag">8折</div>
                <div class="time">6个月</div>
                <div class="special-price"><span class="unit">¥</span>86.4</div>
                <div class="origin-price">¥108.00</div>
                <div class="recharge">立即充值<i class="arrow"/></div>
              </li>
              <li
                class="item"
                @click="onRecharge('https://jz.bm724.com/juzi_zjj/productcharge.html?sn=YHD0001641')">
                <div class="tag">8折</div>
                <div class="time">1年</div>
                <div class="special-price"><span class="unit">¥</span>158.4</div>
                <div class="origin-price">¥198.00</div>
                <div class="recharge">立即充值<i class="arrow"/></div>
              </li>
            </ul>
            <div class="prompt">
                <div class="label"><i class="icon"/>购买须知</div>
                <div class="content">1.请确保充值账号无误，充值成功后不支持退换</div>
                <div class="content">2.黄金会员适用于电脑/手机/Pad，不支持电视端。</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'

export default class Iqiyi extends Vue {
  onRecharge (url: string): void {
    window.location.href = url
  }
}
</script>

<style lang="scss" scoped>
.iqiyi {
    .header {
        display: flex;
        align-items: center;
        height: 90px;
        background: url('~@/assets/images/iqiyi-bg.png') no-repeat right bottom/112px 74px, linear-gradient(270deg, #fffcf0 0%, #f7e8cc 100%);
        padding: 0 30px;
        .logo {
            display: block;
            width: 34px;
            height: 29px;
        }
        .title {
            font-size: 16px;
            font-weight: 500;
            color: #333;
            margin-left: 10px;
        }
    }
    .body {
        padding: 20px 0;
        .slogan {
            height: 40px;
            font-size: 13px;
            color: #7a7a7a;
            line-height: 20px;
            text-align: center;
            .discount {
                color: #de3333;
            }
        }
        .title {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #333;
            line-height: 20px;
            margin: 20px 15px 0 15px;
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            margin: 15px 10px 0 10px;
            .item {
                width: 31%;
                height: 120px;
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid #d3c8b7;
                box-sizing: border-box;
                margin: 0 (7% / 6) 8px (7% / 6);
                padding-top: 20px;
                position: relative;
                &:hover {
                    background: linear-gradient(180deg, #fffcf7 0%, #fff8ec 100%);
                    border: 1px solid #da8645;
                }
                .tag {
                    width: 36px;
                    height: 20px;
                    font-size: 10px;
                    font-weight: 600;
                    color: #843801;
                    background: linear-gradient(259deg, #f1e3ad 0%, #efcf9c 100%);
                    border-radius: 5px 0px 5px 0px;
                    text-align: center;
                    line-height: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .time {
                    height: 20px;
                    font-size: 13px;
                    font-weight: 500;
                    color: #562907;
                    line-height: 20px;
                    text-align: center;
                }
                .special-price {
                    height: 24px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #562907;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 6px;
                    .unit {
                        font-size: 16px;
                    }
                }
                .origin-price {
                    height: 16px;
                    font-size: 11px;
                    color: #aeaeae;
                    text-decoration: line-through;
                    line-height: 16px;
                    text-align: center;
                }
                .recharge {
                    height: 16px;
                    font-size: 12px;
                    color: #e2413a;
                    line-height: 16px;
                    text-align: center;
                    .arrow {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: url('~@/assets/images/icons/arrow-red.png') no-repeat center/10px;
                        vertical-align: middle;
                        margin: -3px 0 0 5px;
                    }
                }
            }
        }
        .prompt {
            background-color: rgba(#fdf7ec, 0.6);
            border-radius: 5px;
            margin: 7px 15px 0 15px;
            padding: 20px;
            .label {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #bb8c61;
                line-height: 20px;
                margin-bottom: 8px;
                .icon {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background: url('~@/assets/images/icons/prompt-golden.png') no-repeat center/14px;
                    vertical-align: middle;
                    margin: -3px 5px 0 0;
                }
            }
            .content {
                font-size: 12px;
                color: #bb8c61;
                line-height: 22px;
            }
        }
    }
}
</style>
